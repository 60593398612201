define("discourse/plugins/discourse-ai/discourse/connectors/topic-list-heading-bottom/desktop-gist-disclosure", ["exports", "@glimmer/component", "discourse/plugins/discourse-ai/discourse/components/ai-gist-disclosure", "@ember/component", "@ember/template-factory"], function (_exports, _component, _aiGistDisclosure, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiTopicGist extends _component.default {
    static shouldRender(outletArgs1) {
      return (
        // "default" can be removed after the glimmer topic list is rolled out
        (outletArgs1?.name === "default" || outletArgs1?.name === "topic.title") && !outletArgs1.bulkSelectEnabled
      );
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <AiGistDisclosure />
      
    */
    {
      "id": "Zf70KxCi",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,null,null],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/topic-list-heading-bottom/desktop-gist-disclosure.js",
      "scope": () => [_aiGistDisclosure.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiTopicGist;
});